import {create} from "zustand";
import {BlogObj} from "../react-app-env";
import sendRequest from "../utils/axios";

interface blogStoreObj {
    blogs: BlogObj[];

    fetchBlogs: ()=>Promise<undefined>;
    findBlog: (term: string)=>void;
}

const blogStore = create<blogStoreObj>((set, get)=>({
    blogs: [],

    fetchBlogs: ()=>{
        return new Promise(async (resolve, reject)=>{
            await sendRequest.get("/blogs")
                .then(({data})=>{
                    set(prev=>({...prev, blogs: data.reverse()}))
                    resolve(undefined);
                })
                .catch(err=>reject(err))
        })
    },
    findBlog: (term)=>{
        // search from local store

    }
}))


export default blogStore;

