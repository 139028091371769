import {BlogObj} from "../react-app-env";
import {humanDate} from "../utils/functions";
import Button from "../uiComponents/Buttons/Button";
import {useNavigate} from "react-router-dom";


interface props {
    blog: BlogObj
}

export default function ListBlog({blog}:props){
    const navigate = useNavigate();

    const read = () => navigate(`./read/${blog._id}`)

    return <div className={"w-full h-full bg-white flex flex-wrap hover:shadow-xl cursor-pointer rounded-lg "}>
        <div className={"flex-1 md:max-w-[40%] h-full relative "}>
            <img className={"w-full h-full rounded-t-lg md:rounded-tr-none md:rounded-l-lg object-cover"} src={blog.img} alt={blog.heading} />
        </div>
        <span className={"flex-1 w-full p-2 grid auto-rows-max"}>
            <h3 className={""}>{blog.heading}</h3>
            <span className={"text-blue-300 italic"}>
                {blog.author},
                {humanDate(blog.createdAt)}
            </span>
            <p className={"line-clamp-2"}>
                {blog.body}
            </p>
            <Button onClick={read} text={"Read more"} className={"m-auto"} />
        </span>
    </div>
}

