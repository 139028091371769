import logo from '../../assets/images/logo1.png'
import {useNavigate} from "react-router-dom";
import {mainNavPaths} from "../../utils/Paths";
import NavBtn from "../Buttons/NavBtn";
import {socials} from "../../data/Contacts";
import IconBtn from "../Buttons/IconBtn";
import MobileDropDown from "../DropDown/MobileDropDown";


const ClickableLogo = () => {
    const navigation = useNavigate();

    const goto = () => navigation("/") //
    return <span className={"flex cursor-pointer "} onClick={goto}>
        <img className={"w-[50px] h-max object-contain mt-auto mb-auto "}
                src={logo} alt={"logo"} />
        <h2 className={"m-auto text-[2.5vmax] text-green-500"}>BLOG</h2>
    </span>
}

export default function NavBar(){

    return <nav className={"w-full h-[70px] bg-white sticky top-0 leading-[70px] flex justify-between shadow-lg z-[800]"}>
        <ClickableLogo />
        <div className={"w-max h-max gap-2 mt-auto mb-auto hidden md:grid grid-flow-col"}>
            {mainNavPaths.map((path, i) => <NavBtn key={i} path={path} />)}
        </div>
        <div className={"w-max h-max grid grid-flow-col mt-auto mb-auto gap-2"}>
            {socials.map((social, i) => <IconBtn key={i} Icon={social.icon} />)}
        </div>
        <div className={"w-max h-max p-2 grid md:hidden grid-flow-col mt-auto mb-auto gap-2"}>
            <MobileDropDown paths={mainNavPaths} />
        </div>
    </nav>
}

