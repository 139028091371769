

function ord(day: number) {
    if (day === 1 || day === 21 || day === 31) {
        return `${day}st`;
    } else if (day === 2 || day === 22) {
        return `${day}nd`;
    } else if (day === 3 || day === 23) {
        return `${day}rd`;
    } else {
        return `${day}th`;
    }
}


export function humanDate(date: Date){
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    date = new Date(date);
    let day = ord(date.getDate());
    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return `${day} ${month}, ${year}`
}

