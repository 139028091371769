import React from 'react';
import './App.css';
import NavBar from "./uiComponents/Navigation/NavBar";
import Footer from "./uiComponents/Navigation/Footer";
import HomePage from "./pages/HomePage";

function App() {
    return (
        <div className="App">
            <NavBar/>
            <HomePage />
            <Footer/>
        </div>
    );
}

export default App;
