import Page from "../uiComponents/Layouts/Page";
import {Route, Routes} from "react-router-dom";
import ListBlogsSub from "../subPage/ListBlogsSub";
import HomeLeftPanel from "../components/HomeLeftPanel";
import ReadBlogSub from "../subPage/ReadBlogSub";
import SearchBlogSub from "../subPage/SearchBlogSub";
import Four04Page from "./Four04Page";
import blogsStore from "../store/BlogsStore";
import {useEffect} from "react";

export default function HomePage(){
    const { blogs, fetchBlogs } = blogsStore();

    useEffect(()=>{
        try {
            if(blogs.length < 1)Promise.resolve(fetchBlogs())
                .catch(err => alert(err))
        } catch (err){

        }
    })

    return <Page>
        <main className={"w-full md:w-[80%] m-auto flex flex-wrap gap-2 "}>
            <HomeLeftPanel />
            <section className={"flex-1  h-full md:h-screen overflow-y-auto list-blogs p-2 "}>
                <Routes>
                    <Route index={true} element={<ListBlogsSub />} />
                    <Route path={"/read/:blog_id"} element={<ReadBlogSub />} />
                    <Route path={"/search/:term"} element={<SearchBlogSub />} />
                    <Route path={"/*"} element={<Four04Page />} />
                </Routes>
            </section>
        </main>
    </Page>
}

