import { useNavigate} from "react-router-dom";
import {socials, usefulLinks} from "../../data/Contacts";
import IconBtn from "../Buttons/IconBtn";
import {IoLocation} from "react-icons/io5";
import {FiExternalLink} from "react-icons/fi";


export default function Footer(){
    const nav = useNavigate();

    return <footer className={"w-full min-h-[50vh] bg-gray-200 mt-[5rem] pt-5 "}>
        <div className={"w-full grid md:grid-cols-3"}>
            <div className={"w-full grid gap-2"} >
                <h2>Follow us</h2>
                {socials.map((social, i)=><span key={i} onClick={()=>window.open(social.link, "_blank")} className={"w-1/2 min-h-[30px] rounded-lg p-1 hover:bg-blue-100 cursor-pointer m-auto grid grid-flow-col auto-cols-max gap-2 leading-[30px] "}>
                    <IconBtn Icon={social.icon} /> <p className={"w-max"}>{social.cont}</p>
                </span>)}

                <h2>Location</h2>
                <span className={"w-1/2 min-h-[30px] rounded-lg p-1 hover:bg-blue-100 cursor-pointer m-auto grid grid-flow-col auto-cols-max gap-2 leading-[30px] "}>
                    <IoLocation className={"fill-blue-400 m-auto"} />
                    <p>CBD, Nairobi Kenya</p>
                </span>
            </div>

            <div>
                <h2>Useful links</h2>
                {usefulLinks.map((link, i) => (
                    <span key={i} onClick={()=>nav(link.link)} className={"w-1/2 min-h-[30px] rounded-lg p-1 hover:bg-blue-100 cursor-pointer m-auto grid grid-flow-col auto-cols-max gap-2 leading-[30px] "}>
                        <FiExternalLink className={"m-auto text-blue-500"}/>
                        <p>{link.name}</p>
                    </span>
                ))}
            </div>

            <div>
                <div >

                </div>
            </div>
        </div>
    </footer>
}

