import {useParams} from "react-router-dom";
import blogsStore from "../store/BlogsStore";
import {useMemo} from "react";
import {BlogObj} from "../react-app-env";
import ListBlog from "../components/ListBlog";
import banner from "../assets/images/search.webp";


export default function SearchBlogSub(){
    const params = useParams();
    const { blogs } = blogsStore();

    const searchFunction = () : BlogObj[] => {
        let term = params.term;
        if (!term && term !== '') return [];
        let x = term.toLowerCase();
        return blogs.filter(b => (
            b.heading.toLowerCase().includes(x) || b.author.toLowerCase().includes(x) ||
            b.body.toLowerCase().includes(x)
        ));
    }

    const found = useMemo(searchFunction, [blogs, params])

    return <div className={"w-full h-full grid auto-rows-max gap-3 "} >
        <div className={"w-full h-[40vmin] flex "}>
            <div className={"absolute w-[40vh] h-full left-0 right-0 top-0 bottom-0 m-auto bg-gradient-to-tr from-blue-200 to-green-400 blur-3xl "} />
            <img src={banner} className={"w-full h-full filter object-top brightness-50 object-cover rounded-lg"} alt={"banner"} />
            <h1 className={"absolute text-6xl w-max h-max center-of-div text-white"}>Find a blog</h1>
        </div>
        <div className={"w-full grid auto-rows-max "}>
            {found.length < 1 && <h1 className={"w-max h-max mr-auto ml-auto"}>No blogs found</h1>}
            {found.map(blog => <ListBlog blog={blog} key={blog._id} />)}
        </div>
    </div>
}

