import {navPath} from "../react-app-env";

export const mainNavPaths: navPath[] = [
    {name: "Home", path: ""},
    {name: "About", path: "https://gonepharm.com/about" },
    {name: "Join Us", path: "https://gonepharm.com/join_us" },
    {name: "Contact", path: "https://gonepharm.com/contact" },
    {name: "Products", path: "https://gonepharm.com/products" },
    {name: "Careers", path: "https://gonepharm.com/careers" },
]

