import SearchInput from "../uiComponents/Inputs/SearchInput";
import blogsStore from "../store/BlogsStore";
import LatestBlog from "./LatestBlog";
import { useState} from "react";
import {useNavigate} from "react-router-dom";


export default function HomeLeftPanel() {
    const { blogs } = blogsStore();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("")

    const search = () => searchTerm.length > 1 ? navigate(`./search/${searchTerm}`) : ()=>{}

    return (
        <section className={"w-full md:w-[300px] p-2 sticky top-0 rounded-lg grid grid-flow-row auto-rows-max gap-2 bg-white shadow-black-200 left "}>
            <span className={"w-full"}>
                <h2 className={"hidden md:block"}>Find a blog</h2>
                <SearchInput search={search} value={searchTerm} onChange={e=>setSearchTerm(e.target.value)} />
            </span>
            <span className={"w-full hidden md:grid gap-2"}>
                <h2>Latest</h2>
                {blogs.slice(0, 2).map(blog=><LatestBlog blog={blog}  key={blog._id} />)}
            </span>

        </section>
    )
}

