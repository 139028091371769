import blogsStore from "../store/BlogsStore";
import {useEffect, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {humanDate} from "../utils/functions";


export default function ReadBlogSub() {
    const {blogs, fetchBlogs} = blogsStore();
    const navigate = useNavigate();
    const params = useParams();

    const blog = useMemo(() => {
        let id = params.blog_id;
        if (!id) return navigate("-1")
        let blog_ = blogs.find(b => b._id === id)
        if (!blog_) return null;
        return blog_;
    }, [params.blog_id, navigate, blogs])

    useEffect(() => {
        if (blogs.length < 1) {
            //fetch blogs
            fetchBlogs().catch(err=>console.log(err))
        }
    }, [blogs, fetchBlogs]);

    return <div data-aos={"zoom-in"} className={"w-full bg-white m-auto rounded-2xl pb-4 "}>
        {!blog ? <h1>We couldn't find this blog post</h1> :
            <article className={"grid"}>
                <span className={"w-full h-[30ch]"}>
                    <img className={"w-full h-full rounded-2xl object-cover "} src={blog.img} alt={""}/>
                </span>
                <h1>{blog.heading}</h1>
                <i>{blog.author}, {humanDate(blog.createdAt)}</i>
                <br/>
                <br/>
                <span className={"w-[90%] m-auto space-y-3"}>
                    {blog.body.split("\n").map((par, i) => <p key={i}>{par}</p>)}
                </span>
            </article>
        }
    </div>
}

