import {BsSearch} from "react-icons/bs";
import TextInput from "./TextInput";
import Button from "../Buttons/Button";
import React from "react";

interface props extends React.InputHTMLAttributes<HTMLInputElement>{
    search: ()=>void;
}

export default function SearchInput({search, ...rest}:props){

    return <div className={" h-[2.5em] flex gap-1"}>
        <BsSearch className={"w-[20px] h-[20px] absolute z-[5] vertical-middle text-[8px] ml-2 "} />
        <TextInput id={"search"} {...rest} className={`pl-[calc(20px+5%)] !h-[2.5em] ${rest.className}`} />
        <Button onClick={search} text={"Search"} className={"h-[2.5em]"} />
    </div>
}

