import banner from "../assets/images/banner.webp";
import blogsStore from "../store/BlogsStore";
import {CgSpinner} from "react-icons/cg";
import ListBlog from "../components/ListBlog";


export default function ListBlogsSub(){
    const { blogs } = blogsStore();

    return <div className={"w-full h-max grid gap-4 "}>
        <div className={"w-full h-[250px] z-50 flex "}>
            {/*<div className={"absolute w-[40vh] h-[40vh] left-0 right-0 top-0 bottom-[-50] m-auto bg-gradient-to-tr from-blue-200 to-green-400 blur-3xl "} />*/}
            <img src={banner} className={"w-full h-full filter object-top brightness-50 object-cover rounded-lg"}
                 alt={"banner"}/>
            <h1 className={"absolute text-6xl w-max h-max center-of-div text-white"}>Blogs</h1>
        </div>
        <div
            className={"absolute w-[40vh] h-[40vh] left-0 right-0 top-1/2 z-0 m-auto bg-gradient-to-tr from-blue-200 to-green-400 blur-3xl "}/>

        <div className={"w-full min-h-[20vh] grid gap-[1rem] bg-gray-100 rounded-md "}>
            {blogs.length < 1 && <CgSpinner className={"w-[30px] h-[30px] text-blue-400 m-auto animate-spin "}/>}
            {blogs.map(blog => <ListBlog blog={blog} key={blog._id}/>)}
        </div>
    </div>
}

