import axios from "axios";

let path = window.location.href.includes("localhost") ?
    "http://localhost:5000/gonep" // uses this when running on localhost
    :
    "https://gonep-backend.onrender.com/gonep"

const sendRequest = axios.create({
    baseURL: path
})

export default sendRequest;

