import {BlogObj} from "../react-app-env";
import {useState} from "react";
import {useNavigate} from "react-router-dom";


interface props {
    blog: BlogObj
}

export default function LatestBlog({blog}:props){
    const [scale, setScale] = useState(false);
    const navigate = useNavigate();

    const read = () => navigate(`./read/${blog._id}`)

    return <div onClick={read} onMouseEnter={()=>setScale(true)} className={"w-full h-[15vh] rounded-xl flex bg-center overflow-hidden"} >
        <img src={blog.img} alt={""} className={`w-full h-full filter md:hover:${scale?"scale-150":""} brightness-[40%] object-cover`} />
        <h4 className={"w-max h-max cursor-pointer pointer-events-none text-white font-bold truncate max-w-[calc(300px-4%)] absolute center-of-div "}>{blog.heading}</h4>
    </div>
}

